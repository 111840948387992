import React, { useEffect } from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import { UserSidebar, UserData } from 'components/user'
import { withRequireAuth } from 'utils/requireAuth'
import { compose } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchUserData } from 'components/user/actions'
import SEO from 'components/seo'

const mapDispatchToProps = dispatch => ({
  fetchUserData: bindActionCreators(fetchUserData, dispatch)
})

const AccountDataPage = ({
  fetchUserData
}) => {
  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  return (
    <div className={'container'}>
      <SEO
        title='Личный кабинет'
      />
      <div className={css.accountPage}>

        <div className={css.title}>
          <h2>Личный кабинет</h2>
        </div>

        <div className={css.flexWrapper}>
          <div className={css.left}>
            <UserSidebar />
          </div>
          <div className={css.right}>
            <UserData />
          </div>
        </div>

      </div>
    </div>
  )
}

export default compose(
  withMainLayout,
  withRequireAuth,
  connect(null, mapDispatchToProps)
)(AccountDataPage)
